<template>
  <div class="loginPage">
    <div class="messageParty d-flex justify-content-center align-items-center">
      <div style="margin-bottom: 3rem">
        <!-- <div class="text-center mb-4 pb-2">
          <img
            src="@/assets/img/successfully-icon.png"
            class="successfullyPng"
            alt="success icon"
            v-if="
              getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
            "
          />
        </div> -->
        <!-- <h3 class="text-center mb-3">
          {{ $g("documentSubmittedSuccessfully!") }}
        </h3> -->
        <div v-html="description"></div>
        <div class="text-center mt-5">
          <k-button @click="returnToOutstandingDocuments">
            returnToOutstandingDocuments
          </k-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPortalPageSetting } from "@/api/home";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
export default {
  components: {},
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      description: "",
      pageName: "ForgotPassword-SubmitSuccessful",
    };
  },
  methods: {
    returnToOutstandingDocuments() {
      this.$router.push({ name: "requiredDocuments" });
    },
  },
  mounted() {
    this.pageName = this.$route.query.pageName;
    getPortalPageSetting(
      pageNameEnum.RequiredDocumentsSubmitSubmitSuccessful
    ).then((data) => {
      this.description = data.data.description;
    });
  },
};
</script>
